import React from 'react'
import { graphql } from 'gatsby'
import { HTMLRenderer, Layout, PageWrapper, CircularInfographics } from '@retina-packages/retina-theme-bootstrap';
import {
  HcptestResetpatientSection,
  HcpMobileBanner,
  HcpPage
} from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle';
import { EsHeaderContainer } from '../../../css/common/style'
import { ImportanceOfgeneticTesingWrapper } from './styles'
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer';
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes'
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders';
import { hcpGTI } from './constants';
import { deriveMultiLogo, deriveSiteMenus, footRefHTMLBuilder, fullHTMLDataBuilder, infographicDataBuilder } from '../../../utils';
import retinaConfig from '../../../utils/retinaConfigs';
import { FooterCalloutBlock } from '@retina-packages/retina-theme-bootstrap/packages/containers/FooterNavigationCallOutContainer/style';
import { ContainerWrapper, EmerFooterBlock, TestorRetestPatientSectionWrapper } from '../../patient/GTB/styles';
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';

/** Renders genetic-testing-importance-page template
 *
 * @param props props
 * @returns
 */
const GeneticTestingBenefitTemplate = (props: Props) => {
  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso
  const pgContextsGTI = props.pageContext
  const htmlStaticFilesGTI = {
    nonSVGImages: pgContextsGTI.nonSVGImages,
    svgMediaImages: pgContextsGTI.svgMediaImages,
    allMediaDocument: pgContextsGTI.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: hcpGTI.hcpMainMenu, siteFooterMenu: hcpGTI.hcpHomeFooterMenu, addHCPPrefix: retinaConfig.addHcpPrefix });
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  const bannerTitle = hcpGTI.hcpBanner
  const carouselTitle = hcpGTI.hcpCarousel
  const footerTitle = hcpGTI.hcpFooter
  const exitPopupTitle = hcpGTI.hcpExitPopup
  const hcpSwitcherTitle = hcpGTI.hcpHCPSwitcher
  const topNavTitle = hcpGTI.hcpTopNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle})


  const hcpReferenceGTB = footRefHTMLBuilder({ blocks, title: hcpGTI.hcpFootRef });
  const siteLogos: any = deriveMultiLogo({ blocks, title: hcpGTI.hcpSiteLogo })
  const { per, text1, text2 } = infographicDataBuilder({ blocks, title: hcpGTI.hcpInfographic });
  const pieInfoGraData = {'per': `${per}`, 'circular_text': text1, 'right_text': text2}
  const htmlData = fullHTMLDataBuilder({ blocks, title: hcpGTI.hcpGTMayPara });
  const retestingData = fullHTMLDataBuilder({ blocks, title: hcpGTI.hcpImpOfRetestPara });
  const calloutSectionData = fullHTMLDataBuilder({ blocks, title: hcpGTI.hcpCalloutSectionPara });
  const revealData = fullHTMLDataBuilder({ blocks, title: hcpGTI.hcpGTCanRevealPara });
  const knowData = fullHTMLDataBuilder({ blocks, title: hcpGTI.hcpDidYouPara });
  const callOutData = fullHTMLDataBuilder({ blocks, title: hcpGTI.hcpNaviCalloutPara });
  const testOrRetestYourPatientsSectionData = fullHTMLDataBuilder({ blocks, title: hcpGTI.hcpTakeActionPara });
  const bannerHTML = fullHTMLDataBuilder({ blocks, title: hcpGTI.hcpBannerHTML });

  return (
    <div className='hcppage'>
    <HcpPage>
      <HcptestResetpatientSection className="hcppatientsection">
      <EsHeaderContainer>
        {/* Retina Canada HCP - "Importance of Genetic Testing" page - Banner component - EN */}
        <HcpMobileBanner className="imp-gen-testing hcpmobilewrapper hcp-genetic-benefits-footer-wrapper">
          {props.pageContext !== null &&
            props.pageContext.metaInfo !== null && (
              <MetaTagContainer metaData={props.pageContext.metaInfo} />
            )}
          <Layout
            title={"siteTitle"}
            location={props.location}
            modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
            data={mainMenu}
            mdata={footerMenu}
            footerData={footerText}
            audience={audience}
            footref={hcpReferenceGTB}
            hcplinks={hcpSwitcher}
            exitPopData={exitData}
            topNavigation={topNavs}
            siteLogos={siteLogos}
            backToTopContent={retinaConfig.backToTopContent}
            gtmBackToTopLabel={hcpGTI.backToTopGTM}
            staticMediaFiles={htmlStaticFilesGTI}
            showRefContent={retinaConfig.showRefContent}
            hideRefContent={retinaConfig.hideRefContent}
            hcpValidate={retinaConfig.hcpValidate}
            preIndexUrl = {retinaConfig.preIndexUrl}
            hcpHomeUrl={retinaConfig.hcpHomeUrl}
            hcpPrefix={retinaConfig.hcpPrefix}
            siteHomeUrl={retinaConfig.siteHomeUrl}
            footerClassName={hcpGTI.footerClassName}
            cpraFooter={true}
            cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
            cpraOT={true}
            cpraOTEnv={process.env.OT_ENV}
            cpraOTuuid={process.env.OT_UUID}
            {... {...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
          >
            <PageWrapper className="pagewrapper importance-genetic">
              <ImportanceOfgeneticTesingWrapper className="imggentestwrap hcp-genetic-benefit-wrapper-container">
                <MobileBanner className="mobile-banner clinical-trials-banner">
                  <HTMLRenderer data={htmlStaticFilesGTI} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
                </MobileBanner>
                {/* Retina Canada HCP - "Importance of Genetic Testing" page - Icon & Text component -EN */}
                <HTMLRenderer data={htmlStaticFilesGTI} html={htmlData} />
                <HTMLRenderer data={htmlStaticFilesGTI} html={retestingData} />
                {/* Retina Canada HCP - "Importance of Genetic Testing" page - Callout component-EN */}
                <HTMLRenderer data={htmlStaticFilesGTI} html={calloutSectionData} tagName="div" className="o-container" key={"calloutsection"} />
                {/* Retina Canada HCP - "Importance of Genetic Testing" page - Donut Chart component -EN */}
                <div className='circular-info'>
                  <CircularInfographics
                    data={pieInfoGraData}
                    audience={audience}
                    filedata={htmlStaticFilesGTI}
                  />
                </div>
                <HTMLRenderer data={htmlStaticFilesGTI} html={knowData} />
                <HTMLRenderer data={htmlStaticFilesGTI} html={revealData} />
                <ContainerWrapper className="container blue-box-wrapper">
                  <TestorRetestPatientSectionWrapper>
                    <HTMLRenderer html={testOrRetestYourPatientsSectionData} data={htmlStaticFilesGTI} tagName='div' className="o-container" />
                  </TestorRetestPatientSectionWrapper>
                </ContainerWrapper>
                <EmerFooterBlock className="emr-footer-block imp-gens">
                  <FooterCalloutBlock className='o-container padding-cards-fix home-page-footer-us'>
                    <HTMLRenderer data={htmlStaticFilesGTI} html={callOutData} tagName='div' className="footer-block footer-block-bg"/>
                  </FooterCalloutBlock>
                </EmerFooterBlock>
              </ImportanceOfgeneticTesingWrapper>
            </PageWrapper>
          </Layout>
        </HcpMobileBanner>
        </EsHeaderContainer>
      </HcptestResetpatientSection>
    </HcpPage>
    </div>
  )
}

export default GeneticTestingBenefitTemplate

export const pageQuery = graphql`
  query ($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ...HcpGTIPageQuery
      }
    }
  }
`
