export const hcpGTI: any = {
  hcpMainMenu: "Main Navigational Menu HCP GCSO - Retina Spain",
  hcpBannerHTML: "Banner - HTML - GTB - HCP - Retina Spain",
  hcpFooter: "Footer HCP - Retina Spain",
  hcpExitPopup: "Exit Popup - HCP - Retina Spain",
  hcpHCPSwitcher: "HCP Switcher Modal Pop Up - Retina Spain",
  hcpTopNav: "Top Navigational Menu HCP - Retina Spain",
  hcpSiteLogo: "Site logos - Retina Spain",
  hcpFootRef: "Reference - GTB - HCP - Retina Spain",
  hcpInfographic: "Infographic - GTB - HCP - Retina Spain",
  hcpGTMayPara: "Genetic Testing May give - GTB - HCP - Retina Spain",
  hcpImpOfRetestPara: "Importance Of Retesting - GTB - HCP - Retina Spain",
  hcpGTCanRevealPara: "Genetic Testing Can Reveal - GTB - HCP - Retina Spain",
  hcpDidYouPara: "Did You Know - GTB - HCP - Retina Spain",
  hcpCalloutSectionPara: "Callout Section - GTB - HCP - Retina Spain",
  hcpTakeActionPara : "Take action to confirm - GTB - HCP - Retina Spain",
  hcpNaviCalloutPara: "Footer callout - GTB - HCP - Retina Spain",
  backToTopGTM: "back to top - genetic testing benefits - hcp",
  footerClassName: "genetic-testing-importance-footer"
}
